import { useRootStore } from '~/store';

export function useYotpoRegion() {
  try {
    const rootStore = useRootStore();
    const yotpoKey = rootStore.yotpoAppKey;
    useHead({
      script: [
        {
          hid: 'yotpo3',
          src: `https://cdn-widgetsrepository.yotpo.com/v1/loader/${yotpoKey}`,
          async: true,
        },
      ],
      link: [
        {
          rel: 'preload',
          as: 'script',
          href: `https://cdn-widgetsrepository.yotpo.com/v1/loader/${yotpoKey}`,
        },
      ],
    });
  } catch (error) {
    console.error('Error in useYotpoRegion'); // eslint-disable-line no-console
    console.error(error); // eslint-disable-line no-console
  }
}
